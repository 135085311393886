import React, { useState, useEffect } from 'react';
import {
    Flex,
    Box,
    VStack,
    Text
} from '@chakra-ui/react';

// import { DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import InterviewsBar from './InterviewsBar.js';
import InterviewDetails from './InterviewDetails.js';

const Interviews = ({ auth, interviewsData, setInterviewsData }) => {

    const [currentInterview, setCurrentInterview] = useState(null);

    useEffect(() => {
        loadInterviewsData();
    // eslint-disable-next-line
    }, []);

    async function loadInterviewsData() {
        try {
          const idToken = await auth.currentUser.getIdToken(true);
          const theURL = `${process.env.REACT_APP_BASE_URL}/interviews`;
    
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const interviewsData = await response.json();
          setInterviewsData(interviewsData);
        } catch (error) {
          console.error(error.message);
        }
      }

      const handleAddInterview = async (type, templateId, templateTitle, templateBody) => {
        try {
          const idToken = await auth.currentUser.getIdToken(true);
          const theURL = process.env.REACT_APP_BASE_URL + Const.sInterviewsPath;
          const response = await fetch(theURL, {
            method: Const.HttpPost,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            }),
            body: JSON.stringify({
              type,
              templateId,
              templateTitle,
              templateBody
            })
          });
    
          // Check if the request was successful
          if(!response.ok) {
            throw new Error('Failed to add interview');
          }
    
          // Parse the added interview from the response
          const addedInterview = await response.json();
    
          // Set the newly added note as the current note
          setCurrentInterview({
            type: addedInterview.type,
            data: addedInterview
          });
    
          loadInterviewsData();
    
        } catch (error) {
          console.error(error.message);
        }
      };

    return (
        <Box
            display={'flex'}
            justifyContent={Const.sCenter}
            alignItems={Const.sCenter}
            height={'100vh'}
            width={'100vw'}
        >
            <Flex
              w={'100%'}
              alignItems={currentInterview ? 'stretch' : Const.sCenter}
            >
                <InterviewsBar
                  interviewsData={interviewsData}
                  currentInterview={currentInterview}
                  setCurrentInterview={setCurrentInterview}
                  handleAddInterview={handleAddInterview}
                />
                { currentInterview ? (
                <InterviewDetails
                    auth={auth}
                    interviewsData={interviewsData}
                    setInterviewsData={setInterviewsData}
                    currentInterview={currentInterview}
                    setCurrentInterview={setCurrentInterview}
                    loadInterviewsData={loadInterviewsData}
                    handleAddInterview={handleAddInterview}
                />) : (
                  <VStack 
                      w={'100%'}
                  >
                  <Text
                      fontSize={14}
                      fontWeight={'bold'}
                      color={Const.Gray100}
                  >
                      Select template or add new interview to start from scratch
                  </Text>
                </VStack>
                )
                }
            </Flex>
        </Box>
    );
};

export default Interviews;
