import React, { useState, useEffect } from 'react';
import {
    Flex,
    Box,
    Text,
    VStack
} from '@chakra-ui/react';

// import { DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import OneOnOnesBar from './OneOnOnesBar';
import OneOnOnesDetails from './OneOnOnesDetails';
import { isEqual } from './Utils';

const OneOnOnes = ({ auth, oneOnOnesData, setOneOnOnesData }) => {
    const [currentOneOnOne, setCurrentOneOnOne] = useState(null);

    useEffect(() => {
        loadOneOnOnesData();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Lazy loading of the data of the meeting
        if(currentOneOnOne && !currentOneOnOne.data) {
            loadOneOnOneDataById(currentOneOnOne.id);
        }
    // eslint-disable-next-line
    }, [currentOneOnOne]);

    async function loadOneOnOnesData() {
        try {
          const queryParams = new URLSearchParams({
                type: '1on1'
          });
          const idToken = await auth.currentUser.getIdToken(true);
          const theURL = `${process.env.REACT_APP_BASE_URL}/meetings?${queryParams}`;
    
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const data = await response.json();
          setOneOnOnesData(data);

        } catch (error) {
          console.error(error.message);
        }
      }

      async function loadOneOnOneDataById(id) {
        try {
          const queryParams = new URLSearchParams({
            id
          });
          const idToken = await auth.currentUser.getIdToken(true);
          const theURL = `${process.env.REACT_APP_BASE_URL}/meetings?${queryParams}`;
      
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const oneOnOne = await response.json();
      
          // Update oneOnOnesData immutably
          const updatedOneOnOnesData = oneOnOnesData.map(oneOnOneItem => {
            if (oneOnOneItem.id === id) {
              return {
                ...oneOnOneItem,
                data: oneOnOne.data // Replace the data property with the new one
              };
            }
            return oneOnOneItem;
          });
      
          // Set the new array of oneOnOnesData
          await setOneOnOnesData(updatedOneOnOnesData);
      
          // Update currentOneOnOne if necessary
          if (currentOneOnOne && currentOneOnOne.id === oneOnOne.id) {
            if (!currentOneOnOne.data || !isEqual(currentOneOnOne.data, oneOnOne.data)) {
              await setCurrentOneOnOne({
                ...currentOneOnOne,
                data: oneOnOne.data
              });
            }
          }
      
        } catch (error) {
          console.error(error.message);
        }
      }

    return (
        <Box
            display={'flex'}
            justifyContent={Const.sCenter}
            alignItems={Const.sCenter}
            height={'100vh'}
            width={'100vw'}
        >
            <Flex
              w={'100%'}
              alignItems={currentOneOnOne ? 'stretch' : Const.sCenter}
            >
                <OneOnOnesBar
                  auth={auth}
                  oneOnOnesData={oneOnOnesData}
                  setOneOnOnesData={setOneOnOnesData}
                  currentOneOnOne={currentOneOnOne}
                  setCurrentOneOnOne={setCurrentOneOnOne}
                />
                { currentOneOnOne ? (
                <OneOnOnesDetails
                  auth={auth}
                  oneOnOnesData={oneOnOnesData}
                  setOneOnOnesData={setOneOnOnesData}
                  currentOneOnOne={currentOneOnOne}
                  setCurrentOneOnOne={setCurrentOneOnOne}
                />) : (
                  <VStack 
                      w={'100%'}
                  >
                  <Text
                      fontSize={14}
                      fontWeight={'bold'}
                      color={Const.Gray100}
                  >
                      Select a 1:1 or create a new one
                  </Text>
                </VStack>
                )
                }
            </Flex>
        </Box>
    );
};

export default OneOnOnes;
