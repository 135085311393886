import React, { useState, useRef } from 'react';
import {
    HStack,
    Input,
    VStack,
    Select,
    Textarea
} from '@chakra-ui/react';

// import { DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';

const Feedback = ({ auth }) => {
    const [title, setTitle] = useState('Add title');
    const titleInputRef = useRef(null);

    return (
        <VStack padding={4} w={'100%'} alignItems={'left'}>
            <HStack>
                <Input
                    ref={titleInputRef}
                    fontSize={22}
                    fontWeight={'bold'}
                    value={title}
                    border={Const.sNone}
                    marginLeft={-4}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    onBlur={(event) => {
                        // handleBlurTitle(event);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            // Remove focus
                            titleInputRef.current.blur();
                            event.preventDefault(); // Prevents any default action
                        }
                    }}
                />
                <Select maxW={'180px'}>
                    <option value='option1'>Feature request</option>
                    <option value='option2'>Bug report</option>
                    <option value='option3'>Other</option>
                </Select>
            </HStack>
            <Textarea
                value={'This is a feedback example'}
                fontSize={16}
                flex={'1'} // Allow Textarea to grow and take up remaining space
                resize={Const.sNone}
                borderColor={Const.Gray100}
                placeholder={'Enter the feedback here'}
                _placeholder={{ color: Const.Gray100 }}
                _hover={{
                    cursor: 'text'
                }}
                onChange={(event) => {
                    // setBody(event.target.value);
                }}
                onBlur={(event) => {
                    // handleBlurBody(event);
                }}
            />
        </VStack>
    );
};

export default Feedback;
