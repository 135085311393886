import {
    Button,
    VStack
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';

const OneOnOnesBar = ({
    auth,
    oneOnOnesData,
    setOneOnOnesData,
    currentOneOnOne,
    setCurrentOneOnOne
}) => {

    const handleAddMeeting = async () => {
        try {
          const idToken = await auth.currentUser.getIdToken(true);
          const theURL = process.env.REACT_APP_BASE_URL + '/meetings';
          const response = await fetch(theURL, {
            method: Const.HttpPost,
            headers: new Headers({
              Authorization: idToken,
              Accept: Const.AppJson,
              [Const.ContentType]: Const.AppJson
            })
          });
    
          // Check if the request was successful
          if(!response.ok) {
            throw new Error('Failed to add meeting');
          }
    
          // Parse the added note from the response
          const addedMeeting = await response.json();
    
          // Add into data store and sort accordingly.
          const copyOneOnOnesData = JSON.parse(JSON.stringify(oneOnOnesData));
          copyOneOnOnesData.push(addedMeeting);
          copyOneOnOnesData.sort((a, b) => a.title.localeCompare(b.title));
          await setOneOnOnesData(copyOneOnOnesData);
    
          // Set the newly added note as the current note
          setCurrentOneOnOne(addedMeeting);
        } catch (error) {
          console.error(error.message);
        }
      };

  return (
    <VStack
        w={'240px'}
        minW={'240px'}
        maxW={'240px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        spacing={'4px'}
    >
        { !oneOnOnesData.empty && oneOnOnesData.map((item, index) => (
          <SideBarButton
              key={index}
              current={currentOneOnOne && item.id === currentOneOnOne.id}
              title={item.title}
              action={() => {
                setCurrentOneOnOne(item);
              }}
          />
      ))}
      <Button
            leftIcon={<AddIcon/>}
            size={'sm'}
            minH={'26px'}
            width={'100%'}
            background={Const.Gray46}
            color={Const.Green500}
            _hover={{ background: Const.Gray69 }}
            onClick={ () => { handleAddMeeting() } }
        >
          Add 1:1
      </Button>
    </VStack>
  );
};

export default OneOnOnesBar;
