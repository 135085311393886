import Const from './Constants';

export const notifUserSignIn = async (auth) => {
    try {
        const idToken = await auth.currentUser.getIdToken(true);
        const theURL = process.env.REACT_APP_BASE_URL + '/signin';
        const response = await fetch(theURL, {
        headers: new Headers({
            Authorization: idToken
        })
        });
        await response.json();
    } catch (error) {
        console.error(error.message);
    }
}

export const handleAuthStateChangeCallback = async (auth, isSignedIn, setIsSignedIn, onSignIn) => {
    // This should be always false it seems
    if(!isSignedIn) {
      const unregisterAuthObserver = auth.onAuthStateChanged(async user => {
        setIsSignedIn(!!user);

        // Notify caller on sign in results
        onSignIn(!!user);

        // Notify backend that user has signed in.
        notifUserSignIn(auth);
      });
      return () => { unregisterAuthObserver(); } // Make sure we un-register Firebase observers when the component unmounts.
    } else {
        // Notify caller on sign in results
        onSignIn(false);
    }
  }

export const handleDeleteTask = async (auth, taskId) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const theURL = process.env.REACT_APP_BASE_URL + '/tasks';
      await fetch(theURL, {
        method: Const.HttpDelete,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify({
          taskId
        })
      });
    } catch (error) {
      console.error(error.message);
    }
}

export const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const  fsDateToString = (date) => {
  // Convert Firestore timestamp to JavaScript Date object
  const d = new Date(date._seconds * 1000); // Multiply seconds by 1000 to convert to milliseconds

  // Now format the date as you wish, for example using `toLocaleDateString`
  const formattedDate = d.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  return formattedDate;
}
