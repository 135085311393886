/*
We want to have:
- Agenda for the next meeting, not to forget to discuss - checkbox items list
- Action items - for both?
- Meeting notes log of every meeting every time, grouped by date

AI part
- AI notes summary
- Perf review AI generation
*/

import React, { useState, useEffect, useRef } from 'react';

import {
    Text,
    VStack,
    Divider,
    Box,
    HStack,
    Button,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Input,
    Spacer
} from '@chakra-ui/react';

import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

import Const from './Constants';
import { fsDateToString } from './Utils';

import EditableCheckbox from './EditableCheckbox';

const OneOnOnesDetails = ({
  auth,
  oneOnOnesData,
  setOneOnOnesData,
  currentOneOnOne,
  setCurrentOneOnOne
}) => {
  // Used as a value for the title input
  const [title, setTitle] = useState(currentOneOnOne ? currentOneOnOne.title : '');
  const titleInputRef = useRef(null);

  useEffect(() => {
    if(currentOneOnOne) {
      if(currentOneOnOne.title !== title) {
        setTitle(currentOneOnOne.title);
      }
    }
  // eslint-disable-next-line
  }, [currentOneOnOne]);

  async function handleDeleteMeeting(meetingId) {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const theURL = process.env.REACT_APP_BASE_URL + '/meetings';
      await fetch(theURL, {
        method: Const.HttpDelete,
        headers: new Headers({
          Authorization: idToken,
          Accept: Const.AppJson,
          [Const.ContentType]: Const.AppJson
        }),
        body: JSON.stringify({
          meetingId
        })
      });

      // Delete from notes data.
      let copyOneOnOnesData = JSON.parse(JSON.stringify(oneOnOnesData));
      copyOneOnOnesData = copyOneOnOnesData.filter(obj => obj.id !== meetingId);
      setOneOnOnesData(copyOneOnOnesData);

      // Object is removed, make sure the details view is gone for it.
      setCurrentOneOnOne(null);

    } catch (error) {
      console.error(error.message);
    }
}

  return (
    <VStack
        width={'100%'}
        h={'100vh'}
        alignItems={'top'}
        paddingLeft={6}
        paddingRight={2}
        paddingTop={2}
        paddingBottom={2}
    >
    <HStack>
    <Input
        ref={titleInputRef}
        fontSize={22}
        fontWeight={'bold'}
        value={title}
        border={Const.sNone}
        marginLeft={-4}
        onChange={(event) => {
            setTitle(event.target.value);
        }}
        onBlur={(event) => {
            // handleBlurTitle(event);
        }}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                // Remove focus
                titleInputRef.current.blur();
                event.preventDefault(); // Prevents any default action
            }
        }}
    />
    <Spacer />
    <Button
        leftIcon={<DeleteIcon/>}
        iconSpacing={0}
        size={'sm'}
        height={'30px'}
        background={Const.Gray69}
        color={Const.Gray165}
        _hover={{ background: Const.Red600 }}
        fontSize={10}
        onClick={() => {
            handleDeleteMeeting(currentOneOnOne.id);
        }}
    />
    </HStack>

    <HStack alignItems={'top'}>

        <VStack
            width={'100%'}
            alignItems={'top'}
        >
            <Text
                align={'left'}
                fontSize={16}
                fontWeight={'bold'}
                w={'76px'}
                color={Const.Gray165}
                _hover={{ cursor: Const.sDefault }}
            >
                Agenda
            </Text>
            <Divider my={1} borderColor={Const.Gray100} />
            {
                currentOneOnOne.data && currentOneOnOne.data.agenda && currentOneOnOne.data.agenda.map((item, index) => (
                    <EditableCheckbox
                        key={index}
                        value={item.title}
                    />
                ))
            }
            <Button
                leftIcon={<AddIcon/>}
                size={'sm'}
                minH={'30px'}
                width={'80px'}
                background={Const.Gray31}
                color={Const.Green500}
                _hover={{ background: Const.Gray69 }}
                onClick={() => {}}
            >
                Agenda
            </Button>
            
        </VStack>

        <VStack
            width={'100%'}
            alignItems={'top'}
        >
            <Text
                align={'left'}
                fontSize={16}
                fontWeight={'bold'}
                color={Const.Gray180}
            >
                Action items
            </Text>
            <Divider my={1} borderColor={Const.Gray100} />
            {
                currentOneOnOne.data && currentOneOnOne.data.actionItems && currentOneOnOne.data.actionItems.map((item, index) => (
                    <EditableCheckbox
                        key={index}
                        value={item.title}
                    />
                ))
            }
            <Button
                leftIcon={<AddIcon/>}
                size={'sm'}
                minH={'30px'}
                width={'110px'}
                background={Const.Gray31}
                color={Const.Green500}
                _hover={{ background: Const.Gray69 }}
                onClick={() => {}}
            >
                Action item
            </Button>
        </VStack>

    </HStack>

    <Box h={4}/>

    <Text
        align={'left'}
        fontSize={16}
        fontWeight={'bold'}
        color={Const.Gray180}
    >
        Meeting notes
    </Text>
    <Divider my={1} borderColor={Const.Gray100} />
    <Box h="100%" overflowY="auto" maxH={'100%'}>
        <Accordion allowMultiple h={'100%'} defaultIndex={[0]}>
            {
                currentOneOnOne.data && currentOneOnOne.data.meetingNotes && currentOneOnOne.data.meetingNotes.map((item, index) => (
                    <AccordionItem key={index} border={Const.sNone}>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left' fontSize={14} fontWeight={'bold'} color={Const.Gray180}>
                                {fsDateToString(item.date)}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                        <Textarea
                            value={item.body}
                            fontSize={16}
                            flex={'1'} // Allow Textarea to grow and take up remaining space
                            resize={Const.sNone}
                            borderColor={Const.Gray100}
                            placeholder={'Enter meeting notes here'}
                            _placeholder={{ color: Const.Gray100 }}
                            _hover={{
                                cursor: 'text'
                            }}
                            onChange={(event) => {
                                // setBody(event.target.value);
                            }}
                            onBlur={(event) => {
                                // handleBlurBody(event);
                            }}
                        />
                        </AccordionPanel>
                    </AccordionItem>
                ))
            }
        </Accordion>
    </Box>

    </VStack>
    );
};

export default OneOnOnesDetails;
