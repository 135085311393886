const Const = {
    /* Names */
    sAppName: 'FlowHero',
    sTasks: 'Tasks',
    sNotes: 'Notes',
    sSignIn: 'SignIn',
    s1on1Meetings: '1:1 Meetings',
    sMeetings: 'Meetings',
    sInterviews: 'Interviews',
    sFeedback: 'Feedback',
    sPositionName: 'Position: Name',
    /* keys */
    sTitle: 'title',
    sBody: 'body',
    sDescription: 'description',
    sDone: 'done',
    sStatus: 'status',
    sOrder: 'order',
    sGlobal: 'global',
    sLocal: 'local',
    sInterview: 'interview',
    sTemplate: 'template',
    sCenter: 'center',
    sNone: 'none',
    HttpPost: 'POST',
    HttpPatch: 'PATCH',
    HttpDelete: 'DELETE',
    AppJson: 'application/json',
    ContentType: 'Content-Type',
    sInterviewsPath: '/interviews',
    sDefault: 'default',
    /* Constants */
    kHeaderFontSize: '40px',
    /* Colors */
    White: '#f6f6f6',
    ActiveColor: '#db4a2b',
    DoneColor: '#22543D',
    DoneLightColor: '#276749',
    SelectColor: '#3182CE',
    Red500: '#E53E3E',
    Red600: '#C53030',
    Red700: '#9B2C2C',
    Gray31: '#1f1f1f',
    Gray36: '#242424',
    Gray46: '#2e2e2e',
    Gray64: '#404040',
    Gray69: '#454545',
    Gray100: '#646464',
    Gray165: '#a5a5a5',
    Gray180: '#b4b4b4',
    Green500: '#38A169'
};
  
export default Const;
