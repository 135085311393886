import {
    VStack,
    Flex,
    Image,
    Spacer,
    Button,
    Divider
} from '@chakra-ui/react';

import {
    EditIcon,
    CheckCircleIcon,
    ChatIcon,
    ViewIcon,
    RepeatIcon,
    InfoOutlineIcon
} from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';

const SideBar = ({ auth, appMode, setAppMode, setIsSignedIn }) => {
    async function handleClickSignOut() {
        await auth.signOut();
        await setIsSignedIn(false);
        await setAppMode(Const.sSignIn);
    }

  return (
    <VStack
        width={'240px'}
        minW={'240px'}
        maxW={'240px'}
        background={Const.Gray46}
        padding={'10px'}
        h={'100vh'}
        spacing={'8px'}
        borderRight={'1px solid'}
        borderColor={Const.Gray64}
    >
        <Flex
            align={Const.sCenter}
            justify={Const.sCenter}>
                <Image src='logo-no-bg-wide.png' height={'32px'} marginRight={'4px'} />
        </Flex>
        <SideBarButton
            current={appMode === Const.sTasks}
            icon={<CheckCircleIcon/>}
            title={Const.sTasks}
            action={() => setAppMode(Const.sTasks)}
        />
        <SideBarButton
            current={appMode === Const.sNotes}
            icon={<EditIcon/>}
            title={Const.sNotes}
            action={() => setAppMode(Const.sNotes)}
        />
        <SideBarButton
            current={appMode === Const.sInterviews}
            icon={<ViewIcon/>}
            title={Const.sInterviews}
            action={() => setAppMode(Const.sInterviews)}
        />
        <SideBarButton
            current={appMode === Const.s1on1Meetings}
            icon={<ChatIcon/>}
            title={Const.s1on1Meetings}
            action={() => setAppMode(Const.s1on1Meetings)}
        />
        <SideBarButton
            current={appMode === Const.sMeetings}
            icon={<RepeatIcon/>}
            title={Const.sMeetings}
            action={() => setAppMode(Const.sMeetings)}
        />
        <Spacer/>
        <SideBarButton
            current={appMode === Const.sFeedback}
            icon={<InfoOutlineIcon/>}
            title={Const.sFeedback}
            action={() => setAppMode(Const.sFeedback)}
        />
        <Divider my={2} borderColor={Const.Gray100} />
        <Button
            size={'sm'}
            height={'30px'}
            fontSize={12}
            background={Const.Gray36}
            color={Const.Gray165}
            _hover={{ background: Const.Red600 }}
            onClick={handleClickSignOut}
            marginLeft={2}
        >
            Sign out
        </Button>
    </VStack>
  );
};

export default SideBar;
